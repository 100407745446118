export const injectInterviewLink = (slug: string): string => {
  switch (slug) {
    case 'satomi-nakahara':
      return 'nakahara';
    case 'ray-hsu':
      return 'hsu';
    case 'hiroaki-yura':
    case 'takuya-suzuki':
    case 'erasmus-brosdau':
    case 'koji-moriga':
    case 'michael-chu':
    case 'soheyl-mohammad':
    case 'yoshihiro-okutou':
      return '';
    default:
      return '';
  }
};
