import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colors, media, commonKeyframes, easings } from '~/styles';
import { useIntersection, useIsJapanese } from '~/hooks';
import { Link } from 'gatsby-plugin-react-i18next';
import { InterviewButton } from '~/components/interview/atoms';

interface CardProps {
  position: string;
  name: string;
  nameSub?: string;
  text: string;
  thumbnail: any;
  hotspot: any;
  twitterId?: string;
  interview?: string;
}

const ALPHABET_ONLY_PATTERN = /^[a-zA-Z\s]*$/;

const Card: React.VFC<CardProps> = ({
  position,
  name,
  nameSub,
  text,
  thumbnail,
  hotspot,
  twitterId,
  interview,
}) => {
  const [isIntersection, intersectionRef] = useIntersection<HTMLLIElement>();
  const isJapanese = useIsJapanese();
  const interviewLabel = isJapanese ? 'インタビュー記事' : 'Interview';

  return (
    <Wrapper ref={intersectionRef} isIntersection={isIntersection}>
      <ThumbnailWrapper>
        <Thumbnail
          style={
            hotspot && {
              '--hotspot-x': `${hotspot.x * 100}%`,
              '--hotspot-y': `${hotspot.y * 100}%`,
            }
          }
          image={thumbnail}
          alt="thumbnail"
        />
      </ThumbnailWrapper>
      <Header>
        <Position>{position}</Position>
        <NameWrapper>
          <Name
            alphabet={ALPHABET_ONLY_PATTERN.test(name)}
            isIntersection={isIntersection}
          >
            {name}
          </Name>
        </NameWrapper>
        {(nameSub || twitterId) && (
          <NameSub isIntersection={isIntersection}>
            {nameSub}
            {nameSub && twitterId && ' / '}
            {twitterId && (
              <>
                <TwitterLink
                  href={`https://twitter.com/${twitterId}`}
                >{`@${twitterId}`}</TwitterLink>
              </>
            )}
          </NameSub>
        )}
        {interview && (
          <InterviewButton
            to={`/interview/${interview}`}
            label={interviewLabel}
          />
        )}
      </Header>

      <Text isIntersection={isIntersection}>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.li<{ isIntersection: boolean }>`
  display: grid;
  grid-gap: 40px;
  opacity: 0;

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic}
        var(--card-delay) forwards;

      --card-delay: 0s;

      ${media.mdUp} {
        &:nth-child(2n) {
          --card-delay: 0.15s;
        }
      }
    `}
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 60%;
  }
`;

const Thumbnail = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  margin: 0;
  background-color: ${colors.lightGray};

  img {
    object-position: var(--hotspot-x) var(--hotspot-y) !important;
  }
`;

const Header = styled.header`
  display: grid;

  ${media.mdUp} {
    grid-gap: 16px;
  }

  ${media.mdDown} {
    grid-gap: 16px;
  }
`;

const Position = styled.p`
  line-height: 2;

  ${media.mdUp} {
    font-size: 16px;
  }

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const NameWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`;

const Name = styled.h2<{ alphabet: boolean; isIntersection: boolean }>`
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin: 0;
  opacity: 0;
  transform: translateY(100%);

  ${media.mdUp} {
    font-size: 40px;
  }

  ${media.mdDown} {
    font-size: 24px;
  }

  ${({ alphabet }) =>
    alphabet &&
    css`
      letter-spacing: 0.02em;
    `}

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.45s ${easings.easeOutCubic}
          forwards,
        ${commonKeyframes.slideIn} 0.45s ${easings.easeOutCubic} forwards;
      animation-delay: calc(var(--card-delay) + 0.6s);
    `}
`;

const NameSub = styled.p<{ isIntersection: boolean }>`
  line-height: 1.25;
  letter-spacing: 0.1em;
  opacity: 0;

  ${media.mdUp} {
    font-size: 16px;
  }

  ${media.mdDown} {
    font-size: 14px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.3s ${easings.easeOutCubic} forwards;
      animation-delay: calc(var(--card-delay) + 0.6s);
    `}
`;

const Text = styled.p<{ isIntersection: boolean }>`
  line-height: 2;
  opacity: 0;

  ${media.mdUp} {
    font-size: 16px;
  }

  ${media.mdDown} {
    font-size: 14px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} forwards;
      animation-delay: calc(var(--card-delay) + 0.9s);
    `}
`;

const TwitterLink = styled.a.attrs({ target: '_blank', rel: 'noreferrer' })`
  position: relative;
  text-transform: lowercase;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0;
    background-color: #fff;
    transition: width 0.6s ${easings.easeOutCubic};
  }

  &:hover {
    &::before {
      transition-duration: 0.3s;
      width: 100%;
    }
  }
`;

export default Card;
